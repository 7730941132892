<template>
  <component :is="timeSheetData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="timeSheetData === undefined">
      <h4 class="alert-heading">Erro ao carregar dados</h4>
      <div class="alert-body">
        Lançamento não encontrado
        <b-link class="alert-link" :to="{ name: 'apps-time-sheet-list' }">
          Lista de funcionários
        </b-link>
        for other timeSheets.
      </div>
    </b-alert>

    <template v-if="timeSheetData">
      <b-card>
        <h2 class="mb-2">{{ $t("message.timeSheet.editReleases") }}</h2>

        <b-overlay
          variant="transparent"
          opacity="1.00"
          blur="6px"
          :show="showOverlay"
          rounded="sm"
          no-wrap
        ></b-overlay>

        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group label="Projeto" label-for="projectId">
                <template v-slot:label>
                  {{ $t("message.projects.project") }}
                </template>
                <v-select
                  class="mt-0"
                  v-model="timeSheetData.projectId"
                  :options="projectOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="project-id"
                  @input="projectTasks(timeSheetData.projectId)"
                >
                </v-select>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="TaskId">
                <template v-slot:label>
                  {{ $t("message.activity.task") }}
                </template>
                <v-select
                  class="mb-1"
                  v-model="timeSheetData.taskId"
                  :options="taskList"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="task-id"
                >
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group label="Data" label-for="createdDate">
                <template v-slot:label>
                  {{ $t("message.tableHeader.date") }}
                </template>
                <div class="mt-0">
                  <flat-pickr
                    id="dateParts"
                    class="form-control"
                    v-model="timeSheetData.date"
                    :config="flatConfig"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="hoursQuantity">
                <template v-slot:label>
                  {{ $t("message.timeSheet.hoursReleased") }}
                </template>

                <b-form-input
                  input-id="hoursQuantity"
                  id="number"
                  type="number"
                  v-model="timeSheetData.hoursQuantity"
                  class="form-control"
                  label="hoursQuantity"
                  label-for="hoursQuantity"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </template>

    <div class="text-right" v-if="timeSheetData">
      <b-button
        variant="primary"
        @click="updateTimeSheets()"
        v-if="
          timeSheetData.hoursQuantity != '' && timeSheetData.hoursQuantity != 0
        "
      >
        {{ $t("message.buttons.save") }}
      </b-button>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormSpinbutton,
  BOverlay,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import getHeader from "@core/utils/auth";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import router from "@/router";
import dateInputEdit from "@/@core/utils/dateInputEdit";
import formValidation from "@core/comp-functions/forms/form-validation";
import store from "@/store";
import AccessControl from "@core/utils/access-control";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormSpinbutton,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },

  data() {
    return {
      //Select
      projectOptions: [],
      taskOptions: [],
      taskList: [],

      subjectsAndProjectsList: [],

      showOverlay: false,
      //CurrentUser
      userData: store.state.user.userData,

      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInputEdit(newVal);
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInputEdit(this.$i18n.locale);
    }
  },

  async created() {
    this.showOverlay = true;
    var teamsByMember = [];
    await axios
      .get(`${URL_API}team/teamsByMember/${this.userData.id}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.content.length; i++) {
          teamsByMember.push(response.data.content[i].id);
        }
      });

    if (this.restrictions("timesheet-list-all")) {
      var projectList = [];
      var projectId = [];
      await axios
        .get(`${URL_API}project/select_list`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i].name;
            const id = response.data[i].id;
            projectList.push(element);
            projectId.push(id);
            this.projectOptions.push({
              label: projectList[i],
              value: projectId[i],
            });
          }
        });
    } else {
      var projectList = [];
      var projectId = [];
      await axios
        .get(`${URL_API}project/${teamsByMember}/selected_list`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i].name;
            const id = response.data[i].id;
            projectList.push(element);
            projectId.push(id);
            this.projectOptions.push({
              label: projectList[i],
              value: projectId[i],
            });
          }
        });
    }

    await axios
      .get(`${URL_API}task/subjectsAndProjects`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        this.subjectsAndProjectsList = response.data;
        for (var i = 0; i < this.subjectsAndProjectsList.length; i++) {
          if (
            this.subjectsAndProjectsList[i].projectId ===
            this.timeSheetData.projectId
          ) {
            this.taskList.push({
              label: this.subjectsAndProjectsList[i].subject,
              value: this.subjectsAndProjectsList[i].id,
            });
          }
        }
      });
      setTimeout(() => {
      this.showOverlay = false;
    }, 1000)
  },

  methods: {
    updateTimeSheets() {
      if (this.timeSheetData.hoursQuantity === "") {
        this.timeSheetData.hoursQuantity = 1;
      }
      if (this.timeSheetData.date === "" || this.timeSheetData.date === null) {
        this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("Error"),
              icon: "XIcon",
              variant: "danger",
              text: this.$i18n.t("invalidDate"),
            },
          });
      } else {
        axios({
          method: "put",
          url: `${URL_API}timeSheet/${this.timeSheetData.id}`,
          headers: getHeader(this.userData),
          data: this.timeSheetData,
        }).then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.saved"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$i18n.t("message.toast.updated"),
            },
          });
          this.$router.push({ name: "apps-time-sheet-list" });
        });
      }
    },

    restrictions(value) {
      return AccessControl(value);
    },

    projectTasks(id) {
      this.taskList = [];
      this.timeSheetData.taskId = null;

      axios
        .get(`${URL_API}task/subjectsAndProjects`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          for (var i = 0; i < this.subjectsAndProjectsList.length; i++) {
            if (
              this.subjectsAndProjectsList[i].projectId ===
              this.timeSheetData.projectId
            ) {
              this.taskList.push({
                label: this.subjectsAndProjectsList[i].subject,
                value: this.subjectsAndProjectsList[i].id,
              });
            }
          }
        });
    },
  },
  setup(props, { emit }) {
    const blankTimeSheetData = {
      date: "",
      projectId: "",
      taskId: "",
    };

    const timeSheetData = ref(JSON.parse(JSON.stringify(blankTimeSheetData)));
    const resettimeSheetData = () => {
      timeSheetData.value = JSON.parse(JSON.stringify(blankTimeSheetData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-time-sheet-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resettimeSheetData);

    return {
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },

  setup() {
    const timeSheetData = ref(null);
    axios({
      method: "get",
      url: `${URL_API}timeSheet/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        timeSheetData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          timeSheetData.value = undefined;
        }
      });
    return {
      timeSheetData,
      vSelect,
    };
  },
};
</script>
